<template>
  <a-row class="ministry">
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>

      <a-row
        class="flex-no__wrap ml-auto wanted-search-input-wrapper"
        type="flex"
        style="justify-content: flex-end"
      />
    </a-row>
    <a-table
      :key="$route.fullPath"
      :loading="loading"
      :columns="columns"
      :data-source="tableProps"
      :scroll="{ x: 1500, y: 600 }"
    >
      <template slot="id" slot-scope="item">
        <span
          class="td-post-item"
          @click="
            $router.push({
              name: item.admin_blog || 'opendata-list',
              params: { open_id: item.id }
            })
          "
        >
          <b>{{ item.id }}</b>
        </span>
      </template>

      <template slot="name" slot-scope="item">
        <div
          style="cursor: pointer"
          @click="
            $router.push({
              name: item.admin_blog || 'opendata-list',
              params: { open_id: item.id }
            })
          "
        >
          {{ item.title || "-" }}
        </div>
      </template>

      <template slot="slug" slot-scope="item">
        {{ item.slug }}
      </template>

      <template slot="status" slot-scope="item">
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? $t("Published") : $t("NotPublished") }}
        </a-tag>
      </template>

      <template slot="created_at" slot-scope="item">
        {{ moment(item.created_at).format("LLL") }}
      </template>

      <template slot="updated_at" slot-scope="item">
        {{ moment(item.updated_at).format("LLL") }}
      </template>
    </a-table>
  </a-row>
</template>

<script>
// import searchInput from "@/components/wanted/search-input"

export default {
  data() {
    return {
      tableProps: [
        /*{
          title: {
            uz: "3299 Nizom bo'yicha ma'lumotlar",
            oz: "3299 Низом бўйича маьлумотлар",
            ru: "3299 Низом бўйича маьлумотлар",
            en: "3299 Низом бўйича маьлумотлар"
          },
          id: 39,
          name: "opendata-list"
        },
        {
          title: {
            uz: "PF-6247 bo'yicha ma'lumotlar",
            oz: "ПФ-6247  бўйича маълумотлар",
            ru: "3299 Низом бўйича маьлумотлар",
            en: "3299 Низом бўйича маьлумотлар"
          },
          id: 40,
          name: "opendata-list"
        }*/
      ],
      loading: false,
      columns: [
        {
          title: "ID",
          key: "id",
          width: "90px",
          fixed: "left",
          scopedSlots: { customRender: "id" }
        },
        {
          title: this.$t("TableTitle"),
          key: "name",
          width: "500px",
          class: "wanted-fio-td",
          scopedSlots: { customRender: "name" }
        },
        {
          title: "Slug",
          key: "slug",
          width: "40%",
          scopedSlots: { customRender: "slug" }
        },
        {
          title: this.$t("Status"),
          key: "status",
          width: "20%",
          scopedSlots: { customRender: "status" }
        },
        {
          title: this.$t("TableCreatedDate"),
          key: "created_datetime",
          width: "30%",
          scopedSlots: { customRender: "created_at" }
        },
        {
          title: this.$t("TableLastUpdatedDate"),
          key: "updated_datetime",
          width: "30%",
          scopedSlots: { customRender: "updated_at" }
        }
      ]
    }
  },
  async created() {
    let id = 38
    let { data } = await this.$api.get(`/admin/menu/${id}/short-detail/`)
    // console.log(data)
    this.tableProps = data && data.children
  },
  methods: {}
}
</script>
<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}
.ministry .ant-table .ant-table-row-indent + .ant-table-row-expand-icon,
.ministry .ant-table-pagination.ant-pagination {
  display: none;
}
</style>
