var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-row',{staticClass:"ministry"},[_c('a-row',{staticClass:"content-title",attrs:{"type":"flex"}},[_c('h2',{staticClass:"flex-1"},[_vm._v(" "+_vm._s(_vm.$route.meta.title[_vm.$i18n.locale])+" ")]),_c('a-row',{staticClass:"flex-no__wrap ml-auto wanted-search-input-wrapper",staticStyle:{"justify-content":"flex-end"},attrs:{"type":"flex"}})],1),_c('a-table',{key:_vm.$route.fullPath,attrs:{"loading":_vm.loading,"columns":_vm.columns,"data-source":_vm.tableProps,"scroll":{ x: 1500, y: 600 }},scopedSlots:_vm._u([{key:"id",fn:function(item){return [_c('span',{staticClass:"td-post-item",on:{"click":function($event){return _vm.$router.push({
            name: item.admin_blog || 'opendata-list',
            params: { open_id: item.id }
          })}}},[_c('b',[_vm._v(_vm._s(item.id))])])]}},{key:"name",fn:function(item){return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({
            name: item.admin_blog || 'opendata-list',
            params: { open_id: item.id }
          })}}},[_vm._v(" "+_vm._s(item.title || "-")+" ")])]}},{key:"slug",fn:function(item){return [_vm._v(" "+_vm._s(item.slug)+" ")]}},{key:"status",fn:function(item){return [_c('a-tag',{attrs:{"color":item.is_active ? 'geekblue' : 'volcano'}},[_vm._v(" "+_vm._s(item.is_active ? _vm.$t("Published") : _vm.$t("NotPublished"))+" ")])]}},{key:"created_at",fn:function(item){return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("LLL"))+" ")]}},{key:"updated_at",fn:function(item){return [_vm._v(" "+_vm._s(_vm.moment(item.updated_at).format("LLL"))+" ")]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }